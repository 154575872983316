/*Custom Fonts*/

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/* Default CSS */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App .line {
  background-color: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  height: 4px;
  width: 10px;
}

p.success {
  color: green;
}

p.danger {
  color: red
};

/* ----- NavBar ----- */

nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32 ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar a.navbar-brand {
  width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
  justify-content: space-between;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}


.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) hue-rotate(346deg) brightness(0);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position:absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"].navbar-toggler-icon {
  border-color: transparent;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ----- Banner ----- */

.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./assets/img/banner-bg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-size: 80px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  color: #B8B8B8;
  font-size: 35px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner .about_me {
  color:rgba(255, 255, 255, 0.5);
  font-size: 25px;
  letter-spacing: 0.8px;
  font-weight: 200;
}

.banner .pipe::after {
  content: '|';
  color: #a9a9a9;
  width: 1px;
}

.banner .no-pipe::after {
  content: '';
}

.banner h3 {
  color: #B8B8B8 !important;
  font-size: 24px;
  font-weight: 300;
}

.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 4s linear infinite;
}

.exper-txtx p {
  font-size: 15px;
  color:rgba(255, 255, 255, 0.5) !important;
}

@keyframes updown {
    0% {
        transform: translateY(-15px);
    }
    50% {
        transform: translateY(15px);
    }
    100% {
        transform: translateY(-15px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Project Css ************/
.project {
  padding: 50px 0 200px 0;
  position: relative;
}
.column {
  padding: 0px;
}

.project h2 {
	font-size: 45px;
	font-weight: 700;
  color: #fff;
  text-align: center;
}


.project p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
    text-align: center;
}

.project h3 {
  color: white;
  font-size: 30px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: 450;
}

.project .left {
  text-align: left !important;
}

.project .left p {
  text-align: left;
}

.project .right {
  text-align: right !important;
}

.project .right p {
  text-align: right;
}

.active.appear {
  animation: fadeInAnimation 2s ease-in-out;
}

.project h5 {
  font-weight: 350 !important;
  color: #fff;
  font-size: 19px !important;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border-radius: 10px;
  width: 120px;
  padding: 12px;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -4;
}

/* ---- Check these Later (Part of Projects)---- */
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  width: 400px;
  height: 400px;
}

.project .img-right {
  position: relative;
  float: right;
}

.project .language {
  font-family: Consolas,monaco,monospace !important;
  color: white !important;
}

.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 1;
  width: 50%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.project .visit {
  font-size: 18px;
  border-radius: 16px;
  border: 3px solid rgba(157, 78, 231, 0.6);
  color: rgba(255, 255, 255, 0.7);
  background-color: #d6b5f5;
  padding: 10px 20px;
  text-decoration: none;
  font-family: 'Centra', sans-serif !important;
}

.project .visit:hover {
  font-size: 19px;
  color: white;
  font-weight: 500;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 40%;
  right: 0;
  z-index: -4;
}

/************ Skills Css ************/
.skills {
  padding-top: 20px;
  padding-bottom: 100px;
  position: relative;
  background-color: black;
  width: 100%;
}

.skills-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.skills h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.skills h3 {
  font-size: 30px;
  font-weight: 400;
}

.skills .git {
  border-radius: 4px;
  background-color: #E0E0E0;
  border: 1px solid #E0E0E0;
}

.skills .flutter {
  padding-bottom: 15px;
}

.skills p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
  padding-bottom: 50px;
}

.skills h4 {
  font-size: 25px !important;
  color: #8F9094;
  font-weight: 200;
}

/*
.skills .back-end {
  animation: fadeInRight 2s ease-in-out;
}

.skills .front-end {
  animation: fadeInLeft 2s ease-in-out;
}*/

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(300px);
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-300px);
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}


.skills img {
  max-width: 75px; 
}


.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}

.active.fade-left {
  animation: fadeInLeft 2s ease-in-out;
}

.active.fade-right {
  animation: fadeInRight 2s ease-in-out;
}


/* --- Contact --- */
.contact {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/* --- Footer --- */

.footer {
  padding-top: 100px;
  padding-bottom: 50px;
}

.footer .seperator {
  width: 80%;
  margin-left: 10%;
  background: white;
  height: 1px;
}
